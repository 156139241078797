import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7a0495b0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header_box"
};
const _hoisted_2 = {
  class: "ctl_box"
};
const _hoisted_3 = {
  class: "table_outer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_BasicTemplate = _resolveComponent("BasicTemplate");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['table_wrapper', _ctx.tableCls])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
    class: _normalizeClass([_ctx.tableStatus === 2 ? 'cursor-not-allowed' : 'cursor-pointer']),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.onToMax && _ctx.onToMax(...args), ["stop"]))
  }, [_createVNode(_component_Icon, {
    name: "ArrowUpBold"
  })], 2), _createElementVNode("span", {
    class: _normalizeClass([_ctx.tableStatus === 0 ? 'cursor-not-allowed' : 'cursor-pointer']),
    onClick: _cache[1] || (_cache[1] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.onToMin && _ctx.onToMin(...args), ["stop"]))
  }, [_createVNode(_component_Icon, {
    name: "ArrowDownBold"
  })], 2)])]), _createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(_component_BasicTemplate, {
    ref: "refTable",
    size: "small",
    height: '100%',
    tableHighlight: true,
    tableColumns: _ctx.tableColumns,
    tableData: _ctx.tableData,
    currentPage: _ctx.current,
    pageSize: _ctx.pageSize,
    total: _ctx.posList.length,
    loading: _ctx.loading,
    showFilter: false,
    tableRowStyle: _ctx.tableRowStyle,
    onCurrentChange: _ctx.currentChange,
    onSizeChange: _ctx.pageSizeChange,
    onTableRowClick: _ctx.onRowClick
  }, {
    index: _withCtx(({
      row,
      $index
    }) => [_createElementVNode("div", {
      ref: el => _ctx.refRow[row.posId] = el
    }, _toDisplayString((_ctx.current - 1) * _ctx.pageSize + ($index + 1)), 513)]),
    posTypeStr: _withCtx(({
      row
    }) => [_createElementVNode("div", null, _toDisplayString(row.posTypeStr ? `定位（${row.posTypeStr}）` : '-'), 1)]),
    speed: _withCtx(({
      row
    }) => [_createElementVNode("div", null, _toDisplayString(row.speed ? `（${row.speed / 10 + "km/h"} ）` : '-'), 1)]),
    mdtstatus: _withCtx(({
      row
    }) => [_createElementVNode("div", null, _toDisplayString(row.mdtstatus & 1 ? '启动' : '熄火'), 1)]),
    _: 1
  }, 8, ["tableColumns", "tableData", "currentPage", "pageSize", "total", "loading", "tableRowStyle", "onCurrentChange", "onSizeChange", "onTableRowClick"]), [[_vShow, _ctx.tableStatus !== 0]])])], 2);
}