/** 判断是否在可视区 */
export function inViewport(el: HTMLElement, parentEl: HTMLElement) {
  const client = el.getBoundingClientRect();
  const parent = parentEl.getBoundingClientRect();

  return (
    client.top > parent.top &&
    client.left > parent.left &&
    client.bottom < parent.bottom &&
    client.right < parent.right
  );
}