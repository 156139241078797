import { defineComponent, reactive, defineAsyncComponent, computed, toRefs, inject, unref, watch } from 'vue';
import { GetGeo } from '@/api/apiFn';
import { isEmpty, isNullOrUnDef } from '@/utils/is';
import { inViewport } from '@/utils/dom';
import { useThrottle } from '@/hooks/core/useThrottle';
var TableStatus;
(function (TableStatus) {
  TableStatus[TableStatus["Min"] = 0] = "Min";
  TableStatus[TableStatus["Normal"] = 1] = "Normal";
  TableStatus[TableStatus["Max"] = 2] = "Max";
})(TableStatus || (TableStatus = {}));
export default defineComponent({
  name: 'TrajectoryTable',
  components: {
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue'))
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const refRoot = inject('RootProvide');
    const refData = reactive({
      refTable: null,
      refRow: {},
      pageSize: 2500,
      current: 1,
      tableStatus: TableStatus.Normal,
      tableColumns: [{
        title: '序号',
        align: 'center',
        key: 'index',
        width: 60,
        slots: {
          customRender: 'index'
        },
        visible: true
      }, {
        title: '设备名',
        align: 'center',
        width: 120,
        key: 'objectname',
        visible: true
      }, {
        title: 'IMEI',
        align: 'center',
        width: 160,
        key: 'mdtid',
        visible: true
      }, {
        title: '客户设备号',
        align: 'center',
        width: 120,
        key: 'mdtid3',
        visible: true
      }, {
        title: '设备型号',
        align: 'center',
        width: 80,
        key: 'deviceType',
        visible: true
      }, {
        title: '定位方式',
        align: 'center',
        width: 120,
        key: 'posTypeStr',
        slots: {
          customRender: 'posTypeStr'
        },
        visible: true
      }, {
        title: '数据时间',
        align: 'center',
        width: 160,
        key: 'devTimeStr',
        visible: true
      }, {
        title: '地址',
        align: 'center',
        minWidth: 500,
        visible: true,
        key: 'address'
      }, {
        title: '速度',
        align: 'center',
        width: 120,
        key: 'speed',
        slots: {
          customRender: 'speed'
        },
        visible: true
      }, {
        title: '状态',
        align: 'center',
        width: 120,
        key: 'mdtstatus',
        slots: {
          customRender: 'mdtstatus'
        },
        visible: true
      }, {
        title: '经度',
        align: 'center',
        minWidth: 120,
        visible: true,
        key: 'lon'
      }, {
        title: '纬度',
        align: 'center',
        minWidth: 120,
        visible: true,
        key: 'lat'
      }]
    });
    const currentPosList = computed(() => refRoot.posList.slice((refData.current - 1) * refData.pageSize, refData.current * refData.pageSize));
    const tableData = computed(() => currentPosList.value && unref(currentPosList).map(pos => ({
      ...refRoot.cardInfo,
      ...pos
    })));
    const tableCls = computed(() => ['table_min', 'table_normal', 'table_max'][refData.tableStatus]);
    function onToMin() {
      if (refData.tableStatus === TableStatus.Max) {
        refData.tableStatus = TableStatus.Normal;
      } else if (refData.tableStatus === TableStatus.Normal) {
        refData.tableStatus = TableStatus.Min;
      }
    }
    function onToMax() {
      if (refData.tableStatus === TableStatus.Min) {
        refData.tableStatus = TableStatus.Normal;
      } else if (refData.tableStatus === TableStatus.Normal) {
        refData.tableStatus = TableStatus.Max;
      }
    }
    function onRowClick(pos) {
      refRoot.currentPosId = pos.posId;
    }
    function currentChange(current) {
      refData.current = current;
    }
    const pageSizeChange = val => {
      refData.pageSize = val;
    };
    function tableRowStyle({
      row,
      rowIndex
    }) {
      if (row.posId === refRoot.currentPosId) {
        return {
          backgroundColor: '#00b9ff'
        };
      }
    }
    // 指定行滚动可视区
    const [panToRow, cancelPanToRow] = useThrottle(posId => {
      const tableBody = refData.refTable.$el.querySelector('.el-table__body-wrapper');
      // 没有在可视区内,当前行滚动到可视区
      if (!isNullOrUnDef(refData.refRow[posId]) && !isNullOrUnDef(tableBody) && !inViewport(refData.refRow[posId], tableBody)) {
        refData.refRow[posId].scrollIntoView(true);
      }
    }, 200);
    watch(currentPosList, async list => {
      if (list.length === 0) {
        return;
      }
      const noAddress = list.filter(p => isEmpty(p.address));
      if (!noAddress.length) return;
      const addressList = await GetGeo(noAddress.map((p, i) => ({
        tag: i,
        lon: p.lon,
        lat: p.lat
      })));
      addressList.forEach(({
        address,
        tag
      }) => {
        noAddress[Number(tag)].address = address;
      });
    });
    watch(() => refRoot.currentPosId, async posId => {
      if (posId === null) {
        return;
      }
      const index = refRoot.posList.findIndex(p => p.posId === posId);
      if (index === -1) return;
      refData.current = 1; // Math.ceil((index + 1) / refData.pageSize)
      panToRow(posId);
    });
    return {
      ...toRefs(refData),
      ...toRefs(refRoot),
      tableData,
      onRowClick,
      currentChange,
      tableRowStyle,
      tableCls,
      onToMin,
      onToMax,
      pageSizeChange
    };
  }
});